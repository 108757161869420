<template>
  <div>
    <Progress v-if="loading" />
    <v-card tile v-if="!loading">
      <v-card-text class="text-center">
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="10">
               <v-row>
                <v-col cols="3">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
                 </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="from_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="from"
                          label="From"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="mdi-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="from"
                        @input="from_menu = false"
                        @change="go('purchase', 'purchase',`branch_id=${branch_id}&from=${from}&to=${to}`)"
                        :max="to"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="to_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="to"
                          label="To"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="mdi-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="to"
                        @input="to_menu = false"
                        @change="go('purchase', 'purchase',`branch_id=${branch_id}&from=${from}&to=${to}`)"
                        :min="from"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                    :items="branchs"
                    v-model="branch_id"
                    label="Branch"
                    item-value="id"
                    item-text="name"
                    @input="go('purchase', 'purchase',`branch_id=${branch_id}&from=${from}&to=${to}`)"
                  ></v-select>
                  </v-col>
                </v-row>
            </v-col>

            <v-col cols="2" align="right">
              <v-btn color="primary" medium tile elevation="1" @click="add()">
                <v-icon left>
                  mdi-plus
                </v-icon>
                Add
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="purchase"
          :page.sync="page"
          :search="search"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          mobile-breakpoint="0"
        >
          <template v-slot:item.discount="{ item }">
            <span @click="openDetail(item)">
              {{ item.discount | numeral("0,0") }}
            </span>
          </template>
          <template v-slot:item.grandtotal="{ item }">
            <span @click="openDetail(item)">
              {{ item.total - item.discount | numeral("0,0") }}
            </span>
          </template>
          <template v-slot:item.total="{ item }">
            <span @click="openDetail(item)">
              {{ item.total | numeral("0,0") }}
            </span>
          </template>
          <template v-slot:item.doc_no="{ item }">
            <span @click="openDetail(item)">
              {{ item.doc_no }}
            </span>
          </template>
          <template v-slot:item.doc_date="{ item }">
            <span @click="openDetail(item)">
              {{ item.doc_date }}
            </span>
          </template>
          <template v-slot:item.name="{ item }">
            <span @click="openDetail(item)">
              {{ item.partner.name }}
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="edit(item)" color="success">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" color="red">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>

        </div>
      </v-card-text>
    </v-card>

    <formInput
      v-on:go="go"
      v-on:close="close"
      :user_edit="user_edit"
      v-if="dialog_form"
    />
    <dialogDelete
      :dialog="dialog_delete"
      data="Purchase"
      v-on:close="close"
      v-on:deleteAction="deleteAction"
    />
    <DetailDialog
      :data="data_detail"
      v-if="dialog_detail"
      v-on:close="openDetail"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Progress from "../../../components/external/Progress";
import { mapActions, mapGetters } from "vuex";
import formInput from "./components/form";
import dialogDelete from "../../../components/external/DialogDelete";
import dialogDetail from "./components/detail";
import GlobalMixin from "../../mixin/index";
export default {
  mixins: [GlobalMixin],
  data() {
    return {
      user_edit: null,
      dialog_delete: false,
      dialog_detail: false,
      data_detail: {},
      from:new Date().toISOString().substr(0, 10),
      to:new Date().toISOString().substr(0, 10),
      branch_id:'',
      from_menu:false,
      to_menu:false,
      branchs:[],
      headers: [

        {
          text: "Date",
          value: "doc_date"
        },
        {
          text: "Document",
          align: "start",
          value: "doc_no"
        },
        {
          text: "Vendor",
          value: "partner.name"
        },
        {
          text: "Disc",
          value: "discount"
        },

        {
          text: "Sub Total",
          value: "total"
        },

        {
          text: "Total",
          value: "grandtotal"
        },

        {
          text: "Actions",
          sortable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      purchase: "data/purchase"
    })
  },
  components: {
    Progress,
    formInput,
    dialogDelete,
    DetailDialog: dialogDetail
  },
  methods: {
    ...mapActions({
      setEditData: "cart/setEditData",
      setCart: "cart/setCart"
    }),

    openDetail: function(item = {}) {
      this.data_detail = item;
      this.dialog_detail = !this.dialog_detail;
    },
    edit(item) {
      let data = {
        id: item.id,
        doc_no: item.doc_no,
        doc_date: item.doc_date,
        doc_type: item.doc_type,
        partner_id: item.partner_id,
        payment_id: item.payment_id,
        branch_id: item.branch_id,
        company_id: item.company_id,
        name: item.name,
        note: item.note,
        status_edit: true
      };
      let cart = item.transaction_details.map(x => {
        let product = {
          id: x.product_id,
          qty: parseInt(x.qty),
          pesan: parseInt(x.qty),
          base_price: parseInt(x.base_price),
          name: x.product.name,
          checkhed: true
        };
        return product;
      });
      this.setCart(cart);
      this.setEditData(data);
      this.add();
    },
    async deleteAction() {
      const data = {
        is_deleted: "Y",
        update_by: this.user.fullname,
        id: this.data_delete.id
      };
      await this.axios
        .put(
          "v1/purchase/delete/" + this.data_delete.id,
          JSON.stringify(data),
          {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              "Content-Type": "application/json",
              Accept: "application/json"
            }
          }
        )
        .then(async ress => {
          this.setSnakbar({
            status: true,
            pesan: "Success delete data",
            color_snakbar: "success"
          });
          await this.go("purchase", "purchase");
          this.close();
        })
        .catch(err => console.log(err.response));
    },
    close() {
      this.setEditData(null);
      this.user_edit = null;
      this.dialog_delete = false;
      this.dialog_form = false;
    }
  },
  async created() {
     this.branchs = this.user.branchs
    this.branch_id = this.user.karyawan.branch_id
    let date = new Date()
    let bln =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
    let year = date.getFullYear()
    this.from = new Date(`${year}-${bln}-01`).toISOString().substr(0, 10)
    if (this.purchase.length) {
      this.loading = false;
    }
  },
  async mounted() {
    await this.go('purchase', 'purchase',`branch_id=${this.branch_id}&from=${this.from}&to=${this.to}`);
  }
};
</script>
